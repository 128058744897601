"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var SvgTwitterwhite = function SvgTwitterwhite(props) {
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 24 20"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("path", {
    d: "M24 2.557a9.843 9.843 0 0 1-2.828.775A4.938 4.938 0 0 0 23.337.608a9.858 9.858 0 0 1-3.127 1.195 4.924 4.924 0 0 0-8.39 4.49A13.979 13.979 0 0 1 1.67 1.15a4.9 4.9 0 0 0-.666 2.476c0 1.708.87 3.215 2.19 4.098a4.904 4.904 0 0 1-2.23-.616v.062a4.927 4.927 0 0 0 3.95 4.828 4.933 4.933 0 0 1-2.224.084 4.929 4.929 0 0 0 4.6 3.42 9.88 9.88 0 0 1-6.115 2.108c-.398 0-.79-.024-1.175-.069a13.937 13.937 0 0 0 7.548 2.212c9.057 0 14.01-7.503 14.01-14.01 0-.213-.005-.425-.015-.636A10.007 10.007 0 0 0 24 2.556z",
    id: "twitterwhite_svg__a"
  })), _react["default"].createElement("use", {
    fill: "#FFF",
    fillRule: "nonzero",
    xlinkHref: "#twitterwhite_svg__a"
  }));
};

var _default = SvgTwitterwhite;
exports["default"] = _default;