import React from 'react'

const SvgYoutubecolor = props => (
  <svg viewBox="0 0 40 40" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M31.76 15.701s-.234-1.653-.954-2.382c-.912-.956-1.935-.96-2.404-1.016-3.359-.243-8.397-.243-8.397-.243h-.01s-5.038 0-8.397.243c-.469.056-1.491.06-2.404 1.016-.72.729-.954 2.382-.954 2.382S8 17.644 8 19.586v1.82c0 1.943.24 3.885.24 3.885s.234 1.654.954 2.382c.913.956 2.112.926 2.646 1.026 1.92.184 8.16.241 8.16.241s5.043-.008 8.402-.25c.469-.056 1.492-.061 2.404-1.017.72-.728.954-2.382.954-2.382s.24-1.942.24-3.884v-1.821c0-1.942-.24-3.885-.24-3.885z"
        fill="#DC2217"
      />
      <path fill="#FFF" d="M17.522 23.613V16.87l6.484 3.383z" />
    </g>
  </svg>
)

export default SvgYoutubecolor
