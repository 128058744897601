import React from 'react'

const SvgYoutubewhite = props => (
  <svg viewBox="0 0 24 17" {...props}>
    <defs>
      <path
        d="M23.76 3.701S24 5.644 24 7.586v1.82c0 1.943-.24 3.885-.24 3.885s-.234 1.654-.954 2.382c-.912.956-1.935.96-2.404 1.017-3.359.242-8.402.25-8.402.25s-6.24-.057-8.16-.241c-.534-.1-1.733-.07-2.646-1.026-.72-.728-.954-2.382-.954-2.382S0 11.349 0 9.407V7.586C0 5.644.24 3.7.24 3.7s.234-1.653.954-2.382C2.107.363 3.129.36 3.598.303 6.957.06 11.995.06 11.995.06h.01s5.038 0 8.397.243c.469.056 1.492.06 2.404 1.016.72.729.954 2.382.954 2.382zM9.522 11.613l6.484-3.36L9.521 4.87l.001 6.743z"
        id="youtubewhite_svg__a"
      />
    </defs>
    <use fill="#FFF" fillRule="nonzero" xlinkHref="#youtubewhite_svg__a" />
  </svg>
)

export default SvgYoutubewhite
