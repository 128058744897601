"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Add", {
  enumerable: true,
  get: function get() {
    return _Add["default"];
  }
});
Object.defineProperty(exports, "Addcircle", {
  enumerable: true,
  get: function get() {
    return _Addcircle["default"];
  }
});
Object.defineProperty(exports, "Adduser", {
  enumerable: true,
  get: function get() {
    return _Adduser["default"];
  }
});
Object.defineProperty(exports, "Addusercolored", {
  enumerable: true,
  get: function get() {
    return _Addusercolored["default"];
  }
});
Object.defineProperty(exports, "Arrowup", {
  enumerable: true,
  get: function get() {
    return _Arrowup["default"];
  }
});
Object.defineProperty(exports, "Bell", {
  enumerable: true,
  get: function get() {
    return _Bell["default"];
  }
});
Object.defineProperty(exports, "Book", {
  enumerable: true,
  get: function get() {
    return _Book["default"];
  }
});
Object.defineProperty(exports, "Buildings", {
  enumerable: true,
  get: function get() {
    return _Buildings["default"];
  }
});
Object.defineProperty(exports, "Calendar", {
  enumerable: true,
  get: function get() {
    return _Calendar["default"];
  }
});
Object.defineProperty(exports, "Call", {
  enumerable: true,
  get: function get() {
    return _Call["default"];
  }
});
Object.defineProperty(exports, "Change", {
  enumerable: true,
  get: function get() {
    return _Change["default"];
  }
});
Object.defineProperty(exports, "Check", {
  enumerable: true,
  get: function get() {
    return _Check["default"];
  }
});
Object.defineProperty(exports, "Circledown", {
  enumerable: true,
  get: function get() {
    return _Circledown["default"];
  }
});
Object.defineProperty(exports, "Clock", {
  enumerable: true,
  get: function get() {
    return _Clock["default"];
  }
});
Object.defineProperty(exports, "Close", {
  enumerable: true,
  get: function get() {
    return _Close["default"];
  }
});
Object.defineProperty(exports, "Code", {
  enumerable: true,
  get: function get() {
    return _Code["default"];
  }
});
Object.defineProperty(exports, "Cog", {
  enumerable: true,
  get: function get() {
    return _Cog["default"];
  }
});
Object.defineProperty(exports, "Colorlens", {
  enumerable: true,
  get: function get() {
    return _Colorlens["default"];
  }
});
Object.defineProperty(exports, "Colorreset", {
  enumerable: true,
  get: function get() {
    return _Colorreset["default"];
  }
});
Object.defineProperty(exports, "Csv", {
  enumerable: true,
  get: function get() {
    return _Csv["default"];
  }
});
Object.defineProperty(exports, "Dentist", {
  enumerable: true,
  get: function get() {
    return _Dentist["default"];
  }
});
Object.defineProperty(exports, "Doc", {
  enumerable: true,
  get: function get() {
    return _Doc["default"];
  }
});
Object.defineProperty(exports, "Doctors", {
  enumerable: true,
  get: function get() {
    return _Doctors["default"];
  }
});
Object.defineProperty(exports, "Documents", {
  enumerable: true,
  get: function get() {
    return _Documents["default"];
  }
});
Object.defineProperty(exports, "Docx", {
  enumerable: true,
  get: function get() {
    return _Docx["default"];
  }
});
Object.defineProperty(exports, "Down", {
  enumerable: true,
  get: function get() {
    return _Down["default"];
  }
});
Object.defineProperty(exports, "Download", {
  enumerable: true,
  get: function get() {
    return _Download["default"];
  }
});
Object.defineProperty(exports, "Edit", {
  enumerable: true,
  get: function get() {
    return _Edit["default"];
  }
});
Object.defineProperty(exports, "Education", {
  enumerable: true,
  get: function get() {
    return _Education["default"];
  }
});
Object.defineProperty(exports, "Eyedroper", {
  enumerable: true,
  get: function get() {
    return _Eyedroper["default"];
  }
});
Object.defineProperty(exports, "Fbcolor", {
  enumerable: true,
  get: function get() {
    return _Fbcolor["default"];
  }
});
Object.defineProperty(exports, "Fbwhite", {
  enumerable: true,
  get: function get() {
    return _Fbwhite["default"];
  }
});
Object.defineProperty(exports, "File", {
  enumerable: true,
  get: function get() {
    return _File["default"];
  }
});
Object.defineProperty(exports, "Glasses", {
  enumerable: true,
  get: function get() {
    return _Glasses["default"];
  }
});
Object.defineProperty(exports, "Globe", {
  enumerable: true,
  get: function get() {
    return _Globe["default"];
  }
});
Object.defineProperty(exports, "Goto", {
  enumerable: true,
  get: function get() {
    return _Goto["default"];
  }
});
Object.defineProperty(exports, "Health", {
  enumerable: true,
  get: function get() {
    return _Health["default"];
  }
});
Object.defineProperty(exports, "Home", {
  enumerable: true,
  get: function get() {
    return _Home["default"];
  }
});
Object.defineProperty(exports, "Hospital", {
  enumerable: true,
  get: function get() {
    return _Hospital["default"];
  }
});
Object.defineProperty(exports, "Info", {
  enumerable: true,
  get: function get() {
    return _Info["default"];
  }
});
Object.defineProperty(exports, "Instacolor", {
  enumerable: true,
  get: function get() {
    return _Instacolor["default"];
  }
});
Object.defineProperty(exports, "Instawhite", {
  enumerable: true,
  get: function get() {
    return _Instawhite["default"];
  }
});
Object.defineProperty(exports, "Left", {
  enumerable: true,
  get: function get() {
    return _Left["default"];
  }
});
Object.defineProperty(exports, "Life", {
  enumerable: true,
  get: function get() {
    return _Life["default"];
  }
});
Object.defineProperty(exports, "Link", {
  enumerable: true,
  get: function get() {
    return _Link["default"];
  }
});
Object.defineProperty(exports, "Lock", {
  enumerable: true,
  get: function get() {
    return _Lock["default"];
  }
});
Object.defineProperty(exports, "Lockcolored", {
  enumerable: true,
  get: function get() {
    return _Lockcolored["default"];
  }
});
Object.defineProperty(exports, "Menu", {
  enumerable: true,
  get: function get() {
    return _Menu["default"];
  }
});
Object.defineProperty(exports, "Money", {
  enumerable: true,
  get: function get() {
    return _Money["default"];
  }
});
Object.defineProperty(exports, "Name", {
  enumerable: true,
  get: function get() {
    return _Name["default"];
  }
});
Object.defineProperty(exports, "Nature", {
  enumerable: true,
  get: function get() {
    return _Nature["default"];
  }
});
Object.defineProperty(exports, "Out", {
  enumerable: true,
  get: function get() {
    return _Out["default"];
  }
});
Object.defineProperty(exports, "Payment", {
  enumerable: true,
  get: function get() {
    return _Payment["default"];
  }
});
Object.defineProperty(exports, "Pdf", {
  enumerable: true,
  get: function get() {
    return _Pdf["default"];
  }
});
Object.defineProperty(exports, "People", {
  enumerable: true,
  get: function get() {
    return _People["default"];
  }
});
Object.defineProperty(exports, "Percent", {
  enumerable: true,
  get: function get() {
    return _Percent["default"];
  }
});
Object.defineProperty(exports, "Picture", {
  enumerable: true,
  get: function get() {
    return _Picture["default"];
  }
});
Object.defineProperty(exports, "Piechart", {
  enumerable: true,
  get: function get() {
    return _Piechart["default"];
  }
});
Object.defineProperty(exports, "Pin", {
  enumerable: true,
  get: function get() {
    return _Pin["default"];
  }
});
Object.defineProperty(exports, "Pinselected", {
  enumerable: true,
  get: function get() {
    return _Pinselected["default"];
  }
});
Object.defineProperty(exports, "Pinuser", {
  enumerable: true,
  get: function get() {
    return _Pinuser["default"];
  }
});
Object.defineProperty(exports, "Position", {
  enumerable: true,
  get: function get() {
    return _Position["default"];
  }
});
Object.defineProperty(exports, "Powerbutton", {
  enumerable: true,
  get: function get() {
    return _Powerbutton["default"];
  }
});
Object.defineProperty(exports, "Pptx", {
  enumerable: true,
  get: function get() {
    return _Pptx["default"];
  }
});
Object.defineProperty(exports, "Remove", {
  enumerable: true,
  get: function get() {
    return _Remove["default"];
  }
});
Object.defineProperty(exports, "Removeuser", {
  enumerable: true,
  get: function get() {
    return _Removeuser["default"];
  }
});
Object.defineProperty(exports, "Right", {
  enumerable: true,
  get: function get() {
    return _Right["default"];
  }
});
Object.defineProperty(exports, "Search", {
  enumerable: true,
  get: function get() {
    return _Search["default"];
  }
});
Object.defineProperty(exports, "Server", {
  enumerable: true,
  get: function get() {
    return _Server["default"];
  }
});
Object.defineProperty(exports, "Services", {
  enumerable: true,
  get: function get() {
    return _Services["default"];
  }
});
Object.defineProperty(exports, "Share", {
  enumerable: true,
  get: function get() {
    return _Share["default"];
  }
});
Object.defineProperty(exports, "Star", {
  enumerable: true,
  get: function get() {
    return _Star["default"];
  }
});
Object.defineProperty(exports, "Tint", {
  enumerable: true,
  get: function get() {
    return _Tint["default"];
  }
});
Object.defineProperty(exports, "Trash", {
  enumerable: true,
  get: function get() {
    return _Trash["default"];
  }
});
Object.defineProperty(exports, "Triangle", {
  enumerable: true,
  get: function get() {
    return _Triangle["default"];
  }
});
Object.defineProperty(exports, "Twittercolor", {
  enumerable: true,
  get: function get() {
    return _Twittercolor["default"];
  }
});
Object.defineProperty(exports, "Twitterwhite", {
  enumerable: true,
  get: function get() {
    return _Twitterwhite["default"];
  }
});
Object.defineProperty(exports, "Txt", {
  enumerable: true,
  get: function get() {
    return _Txt["default"];
  }
});
Object.defineProperty(exports, "Up", {
  enumerable: true,
  get: function get() {
    return _Up["default"];
  }
});
Object.defineProperty(exports, "Upload", {
  enumerable: true,
  get: function get() {
    return _Upload["default"];
  }
});
Object.defineProperty(exports, "Url", {
  enumerable: true,
  get: function get() {
    return _Url["default"];
  }
});
Object.defineProperty(exports, "User", {
  enumerable: true,
  get: function get() {
    return _User["default"];
  }
});
Object.defineProperty(exports, "Users", {
  enumerable: true,
  get: function get() {
    return _Users["default"];
  }
});
Object.defineProperty(exports, "Xlsx", {
  enumerable: true,
  get: function get() {
    return _Xlsx["default"];
  }
});
Object.defineProperty(exports, "Youtubecolor", {
  enumerable: true,
  get: function get() {
    return _Youtubecolor["default"];
  }
});
Object.defineProperty(exports, "Youtubewhite", {
  enumerable: true,
  get: function get() {
    return _Youtubewhite["default"];
  }
});
Object.defineProperty(exports, "Zip", {
  enumerable: true,
  get: function get() {
    return _Zip["default"];
  }
});

var _Add = _interopRequireDefault(require("./Add.js"));

var _Addcircle = _interopRequireDefault(require("./Addcircle.js"));

var _Adduser = _interopRequireDefault(require("./Adduser.js"));

var _Addusercolored = _interopRequireDefault(require("./Addusercolored.js"));

var _Arrowup = _interopRequireDefault(require("./Arrowup.js"));

var _Bell = _interopRequireDefault(require("./Bell.js"));

var _Book = _interopRequireDefault(require("./Book.js"));

var _Buildings = _interopRequireDefault(require("./Buildings.js"));

var _Calendar = _interopRequireDefault(require("./Calendar.js"));

var _Call = _interopRequireDefault(require("./Call.js"));

var _Change = _interopRequireDefault(require("./Change.js"));

var _Check = _interopRequireDefault(require("./Check.js"));

var _Circledown = _interopRequireDefault(require("./Circledown.js"));

var _Clock = _interopRequireDefault(require("./Clock.js"));

var _Close = _interopRequireDefault(require("./Close.js"));

var _Code = _interopRequireDefault(require("./Code.js"));

var _Cog = _interopRequireDefault(require("./Cog.js"));

var _Colorlens = _interopRequireDefault(require("./Colorlens.js"));

var _Colorreset = _interopRequireDefault(require("./Colorreset.js"));

var _Csv = _interopRequireDefault(require("./Csv.js"));

var _Dentist = _interopRequireDefault(require("./Dentist.js"));

var _Doc = _interopRequireDefault(require("./Doc.js"));

var _Doctors = _interopRequireDefault(require("./Doctors.js"));

var _Documents = _interopRequireDefault(require("./Documents.js"));

var _Docx = _interopRequireDefault(require("./Docx.js"));

var _Down = _interopRequireDefault(require("./Down.js"));

var _Download = _interopRequireDefault(require("./Download.js"));

var _Edit = _interopRequireDefault(require("./Edit.js"));

var _Education = _interopRequireDefault(require("./Education.js"));

var _Eyedroper = _interopRequireDefault(require("./Eyedroper.js"));

var _Fbcolor = _interopRequireDefault(require("./Fbcolor.js"));

var _Fbwhite = _interopRequireDefault(require("./Fbwhite.js"));

var _File = _interopRequireDefault(require("./File.js"));

var _Glasses = _interopRequireDefault(require("./Glasses.js"));

var _Globe = _interopRequireDefault(require("./Globe.js"));

var _Goto = _interopRequireDefault(require("./Goto.js"));

var _Health = _interopRequireDefault(require("./Health.js"));

var _Home = _interopRequireDefault(require("./Home.js"));

var _Hospital = _interopRequireDefault(require("./Hospital.js"));

var _Info = _interopRequireDefault(require("./Info.js"));

var _Instacolor = _interopRequireDefault(require("./Instacolor.js"));

var _Instawhite = _interopRequireDefault(require("./Instawhite.js"));

var _Left = _interopRequireDefault(require("./Left.js"));

var _Life = _interopRequireDefault(require("./Life.js"));

var _Link = _interopRequireDefault(require("./Link.js"));

var _Lock = _interopRequireDefault(require("./Lock.js"));

var _Lockcolored = _interopRequireDefault(require("./Lockcolored.js"));

var _Menu = _interopRequireDefault(require("./Menu.js"));

var _Money = _interopRequireDefault(require("./Money.js"));

var _Name = _interopRequireDefault(require("./Name.js"));

var _Nature = _interopRequireDefault(require("./Nature.js"));

var _Out = _interopRequireDefault(require("./Out.js"));

var _Payment = _interopRequireDefault(require("./Payment.js"));

var _Pdf = _interopRequireDefault(require("./Pdf.js"));

var _People = _interopRequireDefault(require("./People.js"));

var _Percent = _interopRequireDefault(require("./Percent.js"));

var _Picture = _interopRequireDefault(require("./Picture.js"));

var _Piechart = _interopRequireDefault(require("./Piechart.js"));

var _Pin = _interopRequireDefault(require("./Pin.js"));

var _Pinselected = _interopRequireDefault(require("./Pinselected.js"));

var _Pinuser = _interopRequireDefault(require("./Pinuser.js"));

var _Position = _interopRequireDefault(require("./Position.js"));

var _Powerbutton = _interopRequireDefault(require("./Powerbutton.js"));

var _Pptx = _interopRequireDefault(require("./Pptx.js"));

var _Remove = _interopRequireDefault(require("./Remove.js"));

var _Removeuser = _interopRequireDefault(require("./Removeuser.js"));

var _Right = _interopRequireDefault(require("./Right.js"));

var _Search = _interopRequireDefault(require("./Search.js"));

var _Server = _interopRequireDefault(require("./Server.js"));

var _Services = _interopRequireDefault(require("./Services.js"));

var _Share = _interopRequireDefault(require("./Share.js"));

var _Star = _interopRequireDefault(require("./Star.js"));

var _Tint = _interopRequireDefault(require("./Tint.js"));

var _Trash = _interopRequireDefault(require("./Trash.js"));

var _Triangle = _interopRequireDefault(require("./Triangle.js"));

var _Twittercolor = _interopRequireDefault(require("./Twittercolor.js"));

var _Twitterwhite = _interopRequireDefault(require("./Twitterwhite.js"));

var _Txt = _interopRequireDefault(require("./Txt.js"));

var _Up = _interopRequireDefault(require("./Up.js"));

var _Upload = _interopRequireDefault(require("./Upload.js"));

var _Url = _interopRequireDefault(require("./Url.js"));

var _User = _interopRequireDefault(require("./User.js"));

var _Users = _interopRequireDefault(require("./Users.js"));

var _Xlsx = _interopRequireDefault(require("./Xlsx.js"));

var _Youtubecolor = _interopRequireDefault(require("./Youtubecolor.js"));

var _Youtubewhite = _interopRequireDefault(require("./Youtubewhite.js"));

var _Zip = _interopRequireDefault(require("./Zip.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }