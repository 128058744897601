"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var SvgYoutubewhite = function SvgYoutubewhite(props) {
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 24 17"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("path", {
    d: "M23.76 3.701S24 5.644 24 7.586v1.82c0 1.943-.24 3.885-.24 3.885s-.234 1.654-.954 2.382c-.912.956-1.935.96-2.404 1.017-3.359.242-8.402.25-8.402.25s-6.24-.057-8.16-.241c-.534-.1-1.733-.07-2.646-1.026-.72-.728-.954-2.382-.954-2.382S0 11.349 0 9.407V7.586C0 5.644.24 3.7.24 3.7s.234-1.653.954-2.382C2.107.363 3.129.36 3.598.303 6.957.06 11.995.06 11.995.06h.01s5.038 0 8.397.243c.469.056 1.492.06 2.404 1.016.72.729.954 2.382.954 2.382zM9.522 11.613l6.484-3.36L9.521 4.87l.001 6.743z",
    id: "youtubewhite_svg__a"
  })), _react["default"].createElement("use", {
    fill: "#FFF",
    fillRule: "nonzero",
    xlinkHref: "#youtubewhite_svg__a"
  }));
};

var _default = SvgYoutubewhite;
exports["default"] = _default;