"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var SvgColorreset = function SvgColorreset(props) {
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 24 24"
  }, props), _react["default"].createElement("path", {
    d: "M7.73 20C3.93 20 0 17.109 0 12.27 0 7.648 7.104.435 7.406.13a.469.469 0 0 1 .646 0c.302.305 7.407 7.518 7.407 12.142 0 4.838-3.93 7.729-7.73 7.729zm0-18.895C6.334 2.577.91 8.547.91 12.271c0 4.269 3.467 6.82 6.82 6.82 3.352 0 6.82-2.551 6.82-6.82 0-3.726-5.427-9.694-6.82-11.166zm0 14.348c-.826 0-3.183-.584-3.183-3.182a.455.455 0 0 1 .909 0c0 2.175 2.25 2.273 2.273 2.273a.455.455 0 0 1 0 .91zm8.4 8.405a.444.444 0 0 1 0-.628l3.241-3.242-3.24-3.24a.444.444 0 1 1 .628-.63l3.24 3.242 3.242-3.241a.444.444 0 1 1 .629.628l-3.242 3.241 3.242 3.242a.444.444 0 1 1-.629.628L20 20.617l-3.241 3.241a.443.443 0 0 1-.629 0z"
  }));
};

var _default = SvgColorreset;
exports["default"] = _default;