"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var SvgAddusercolored = function SvgAddusercolored(props) {
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 48 48"
  }, props), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "#DAC2A7",
    d: "M17 24h12v15H17z"
  }), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "#EAD8C5",
    d: "M34 12v8c0 6.065-4.935 11-11 11s-11-4.935-11-11v-8h22z"
  }), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "#444",
    d: "M45 44.01c0-1.663-1.229-3.548-2.75-4.213L29 34c-1.688 1.25-3.754 2-6 2s-4.312-.75-5.982-2L3.754 39.797C2.233 40.46 1 42.35 1 44.009v2.988C1 47.551 1.45 48 2.002 48h41.996A.995.995 0 0 0 45 46.997V44.01z"
  }), _react["default"].createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "#C6A279",
    d: "M12 11.999c0-3.313 2.535-6.904 5.654-8.018l7.406-2.645c.519-.186 1.14.062 1.384.552L28 5c3.314 0 6 2.693 6 6v6a5 5 0 0 0-5-5H17a5 5 0 0 0-5 5v-5.001z"
  }), _react["default"].createElement("circle", {
    fill: "#72C472",
    cx: 39,
    cy: 37,
    r: 8
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M42 36h-2v-2a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2z"
  }));
};

var _default = SvgAddusercolored;
exports["default"] = _default;