"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var SvgUrl = function SvgUrl(props) {
  return _react["default"].createElement("svg", _extends({
    id: "url_svg__Calque_1",
    x: 0,
    y: 0,
    viewBox: "0 0 48 48",
    xmlSpace: "preserve"
  }, props), _react["default"].createElement("style", null, '.url_svg__st2{fill:#005792}'), _react["default"].createElement("path", {
    d: "M41 47H7c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h24l12 12v32c0 1.1-.9 2-2 2z",
    fill: "#e6e6e6"
  }), _react["default"].createElement("path", {
    d: "M31 1v10c0 1.1.9 2 2 2h10L31 1z",
    fill: "#b3b3b3"
  }), _react["default"].createElement("path", {
    className: "url_svg__st2",
    d: "M19.2 38c-1.5 0-3.1-.6-4.3-1.8-2.4-2.4-2.4-6.1 0-8.5l2.5-2.4c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-2.5 2.4c-1.6 1.6-1.6 4 0 5.7 1.6 1.6 4.1 1.6 5.7 0l2.8-2.8c1.6-1.6 1.6-4.1 0-5.7-.4-.4-.4-1 0-1.4s1-.4 1.4 0c2.4 2.4 2.4 6.1 0 8.5l-2.8 2.8c-1.1 1.2-2.6 1.8-4.2 1.8z"
  }), _react["default"].createElement("path", {
    className: "url_svg__st2",
    d: "M22.8 29.4c-.3 0-.5-.1-.7-.3-2.4-2.4-2.4-6.1 0-8.5l2.8-2.8c2.4-2.4 6.1-2.4 8.5 0s2.4 6.1 0 8.5l-2.5 2.4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l2.5-2.4c1.6-1.6 1.6-4 0-5.7-1.6-1.6-4.1-1.6-5.7 0L23.5 22c-1.6 1.6-1.6 4.1 0 5.7.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"
  }));
};

var _default = SvgUrl;
exports["default"] = _default;