import React from 'react'

const SvgInstawhite = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        d="M12 0c3.259 0 3.668.014 4.948.072 1.95.089 3.663.567 5.038 1.942 1.375 1.376 1.853 3.089 1.942 5.039C23.986 8.332 24 8.74 24 12c0 3.259-.014 3.668-.072 4.948-.089 1.95-.567 3.663-1.942 5.038-1.376 1.375-3.089 1.853-5.038 1.942-1.28.058-1.689.072-4.948.072-3.259 0-3.668-.014-4.947-.072-1.952-.089-3.664-.567-5.039-1.942C.639 20.61.161 18.898.072 16.948.014 15.668 0 15.259 0 12c0-3.259.014-3.668.072-4.947C.161 5.1.639 3.389 2.014 2.014 3.39.639 5.103.161 7.053.072 8.332.014 8.74 0 12 0zm0 5.441c2.136 0 2.389.008 3.232.047.911.041 1.756.224 2.406.874.65.65.832 1.495.874 2.405.038.844.046 1.097.046 3.233s-.008 2.389-.046 3.232c-.041.911-.224 1.756-.874 2.406-.65.65-1.495.832-2.406.874-.843.038-1.096.046-3.232.046s-2.389-.008-3.232-.046c-.911-.041-1.756-.224-2.406-.874-.65-.65-.833-1.495-.874-2.406-.039-.843-.047-1.096-.047-3.232s.008-2.389.047-3.232c.041-.911.224-1.756.874-2.406.65-.65 1.495-.833 2.405-.874.844-.039 1.097-.047 3.233-.047zM12 4c-2.172 0-2.445.009-3.299.048-1.3.06-2.442.378-3.359 1.295C4.426 6.259 4.109 7.4 4.049 8.7 4.009 9.555 4 9.829 4 12c0 2.172.009 2.445.048 3.299.06 1.3.378 2.442 1.295 3.358.916.917 2.058 1.235 3.358 1.295.854.039 1.127.048 3.299.048 2.172 0 2.445-.009 3.299-.048 1.3-.06 2.442-.378 3.358-1.295.917-.916 1.235-2.058 1.295-3.358.039-.854.048-1.127.048-3.299 0-2.172-.009-2.445-.048-3.299-.06-1.3-.378-2.442-1.295-3.359-.916-.916-2.058-1.234-3.358-1.294C14.445 4.009 14.171 4 12 4zm0 3.892a4.108 4.108 0 1 0 0 8.216 4.108 4.108 0 0 0 0-8.216zm0 6.774a2.666 2.666 0 1 1 0-5.332 2.666 2.666 0 0 1 0 5.332zm4.27-5.976a.96.96 0 1 0 0-1.92.96.96 0 0 0 0 1.92z"
        id="instawhite_svg__a"
      />
    </defs>
    <use fill="#FFF" fillRule="nonzero" xlinkHref="#instawhite_svg__a" />
  </svg>
)

export default SvgInstawhite
