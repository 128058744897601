import React from 'react'

const SvgHome = props => (
  <svg viewBox="0 0 24 22" {...props}>
    <defs>
      <path
        id="home_svg__a"
        d="M22.087 30.912a.522.522 0 0 1-.522-.521v-4.696h-3.13v4.696a.522.522 0 0 1-.522.521h-6.26a.522.522 0 0 1-.523-.521V18.29l-2.257 2.052a.523.523 0 0 1-.702-.772L19.649 9.135c.2-.18.503-.18.702 0L31.829 19.57a.522.522 0 1 1-.702.772l-2.258-2.052v12.1a.522.522 0 0 1-.521.522h-6.261zm-4.174-6.26h4.174c.288 0 .522.233.522.521v4.696h5.217V17.342L20 10.227l-7.826 7.114V29.87h5.217v-4.696c0-.288.234-.521.522-.521zM22.609 21a.522.522 0 0 1-.522.521h-4.174a.522.522 0 0 1-.522-.521v-4.174c0-.289.234-.522.522-.522h4.174c.288 0 .522.233.522.522V21zm-1.044-.522v-3.13h-3.13v3.13h3.13z"
      />
    </defs>
    <use transform="translate(-8 -9)" xlinkHref="#home_svg__a" />
  </svg>
)

export default SvgHome
