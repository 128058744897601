"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var SvgBuildings = function SvgBuildings(props) {
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 21 24"
  }, props), _react["default"].createElement("defs", null, _react["default"].createElement("path", {
    id: "buildings_svg__a",
    d: "M20.452 8c.232 0 .387.155.387.387v2.71H22c.232 0 .387.155.387.387v1.935h1.936c.232 0 .387.155.387.387v6.581h5.806c.232 0 .387.155.387.387v10.839c0 .232-.155.387-.387.387H10.387c-.232 0-.387-.155-.387-.387v-8.516c0-.232.155-.387.387-.387h5.807v-8.904c0-.232.154-.387.387-.387h1.935v-1.935c0-.232.155-.387.387-.387h1.162v-2.71c0-.232.154-.387.387-.387zm-9.678 15.484v7.742h7.742v-7.742h-7.742zm19.355 7.742V21.16h-7.742v10.065h7.742zM22 14.194c-.232 0-.387-.155-.387-.388v-1.935H19.29v1.935c0 .233-.155.388-.387.388h-1.935v8.516h1.935c.232 0 .387.155.387.387v8.129h2.323V20.774c0-.232.155-.387.387-.387h1.935v-6.193H22zm1.935 9.677c0-.232.155-.387.388-.387h.774c.232 0 .387.155.387.387s-.155.387-.387.387h-.774c-.233 0-.388-.155-.388-.387zm1.162 1.935c.232 0 .387.155.387.388 0 .232-.155.387-.387.387h-.774c-.233 0-.388-.155-.388-.387 0-.233.155-.388.388-.388h.774zm0 2.323c.232 0 .387.155.387.387s-.155.387-.387.387h-.774c-.233 0-.388-.155-.388-.387s.155-.387.388-.387h.774zm2.322-3.87c-.232 0-.387-.156-.387-.388s.155-.387.387-.387h.775c.232 0 .387.155.387.387s-.155.387-.387.387h-.775zm0 2.322c-.232 0-.387-.155-.387-.387 0-.233.155-.388.387-.388h.775c.232 0 .387.155.387.388 0 .232-.155.387-.387.387h-.775zm0 2.322c-.232 0-.387-.155-.387-.387s.155-.387.387-.387h.775c.232 0 .387.155.387.387s-.155.387-.387.387h-.775zm-10.838-3.097c.232 0 .387.155.387.388 0 .232-.155.387-.387.387h-.775c-.232 0-.387-.155-.387-.387 0-.233.155-.388.387-.388h.775zm0 2.323c.232 0 .387.155.387.387s-.155.387-.387.387h-.775c-.232 0-.387-.155-.387-.387s.155-.387.387-.387h.775zm-3.097-2.323c.232 0 .387.155.387.388 0 .232-.155.387-.387.387h-.774c-.233 0-.387-.155-.387-.387 0-.233.154-.388.387-.388h.774zm0 2.323c.232 0 .387.155.387.387s-.155.387-.387.387h-.774c-.233 0-.387-.155-.387-.387s.154-.387.387-.387h.774z"
  })), _react["default"].createElement("use", {
    transform: "translate(-10 -8)",
    xlinkHref: "#buildings_svg__a"
  }));
};

var _default = SvgBuildings;
exports["default"] = _default;