"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Chevron = _interopRequireDefault(require("./Chevron"));

var _Hamburger = _interopRequireDefault(require("./Hamburger"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var AnimatedIcons = {
  chevron: _Chevron["default"],
  hamburger: _Hamburger["default"]
};
var _default = AnimatedIcons;
exports["default"] = _default;