import React from 'react'

const SvgCsv = props => (
  <svg viewBox="0 0 48 48" {...props}>
    <path fill="#0E512B" d="M44 27H4l-3-4 4-4h38l4 4z" />
    <path
      fill="#E6E6E6"
      d="M41 47H7a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h24l12 12v32a2 2 0 0 1-2 2z"
    />
    <path fill="#B3B3B3" d="M31 1v10a2 2 0 0 0 2 2h10L31 1z" />
    <path fill="#1B7343" d="M45 41H3a2 2 0 0 1-2-2V23h46v16a2 2 0 0 1-2 2z" />
    <path
      fill="#FFF"
      d="M17.786 29.775c-.505 0-.899.206-1.182.62-.283.413-.424.983-.424 1.71 0 1.512.575 2.268 1.724 2.268.347 0 .684-.049 1.01-.146s.653-.213.983-.349v1.793c-.655.291-1.396.436-2.224.436-1.185 0-2.094-.344-2.726-1.031-.632-.688-.948-1.682-.948-2.98 0-.813.153-1.527.459-2.144a3.293 3.293 0 0 1 1.321-1.421c.575-.331 1.251-.496 2.028-.496.849 0 1.66.184 2.433.553l-.65 1.67c-.29-.136-.58-.25-.87-.344a3.04 3.04 0 0 0-.934-.139zM26.562 33.615c0 .487-.124.919-.371 1.297s-.604.672-1.069.881c-.465.21-1.012.314-1.638.314-.523 0-.961-.037-1.316-.11s-.723-.201-1.106-.384v-1.891c.405.208.825.37 1.262.486s.838.174 1.203.174c.315 0 .546-.054.693-.163s.22-.25.22-.422a.473.473 0 0 0-.088-.282 1.046 1.046 0 0 0-.285-.244c-.13-.082-.479-.251-1.044-.505-.512-.232-.896-.458-1.152-.677a2.075 2.075 0 0 1-.569-.752 2.485 2.485 0 0 1-.186-1.004c0-.724.263-1.288.79-1.692s1.25-.606 2.17-.606c.813 0 1.642.188 2.487.563l-.65 1.638c-.734-.336-1.368-.504-1.901-.504-.276 0-.476.048-.602.145s-.188.217-.188.359c0 .154.08.292.239.414.159.122.591.344 1.297.666.677.305 1.146.631 1.41.98.262.35.394.789.394 1.319zM32.186 28.147h2.374L31.987 36h-2.498l-2.562-7.853h2.385l1.069 3.979c.222.885.344 1.501.365 1.848.025-.25.075-.563.15-.939s.142-.672.199-.887l1.091-4.001z"
    />
  </svg>
)

export default SvgCsv
