import React from 'react'

const SvgPercent = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="percent_svg__a"
        d="M20 8c6.626 0 12 5.374 12 12s-5.374 12-12 12S8 26.626 8 20 13.374 8 20 8zm0 22.957c6.052 0 10.957-4.905 10.957-10.957 0-6.052-4.905-10.957-10.957-10.957-6.052 0-10.957 4.905-10.957 10.957 0 6.052 4.905 10.957 10.957 10.957zm-1.043-14.61a2.583 2.583 0 0 1-2.61 2.61 2.583 2.583 0 0 1-2.608-2.61 2.583 2.583 0 0 1 2.609-2.608 2.583 2.583 0 0 1 2.609 2.609zm-4.174 0c0 .888.678 1.566 1.565 1.566.887 0 1.565-.678 1.565-1.565 0-.887-.678-1.565-1.565-1.565-.887 0-1.565.678-1.565 1.565zm9.39 5.218a2.583 2.583 0 0 1 2.61 2.609 2.583 2.583 0 0 1-2.61 2.609 2.583 2.583 0 0 1-2.608-2.61 2.583 2.583 0 0 1 2.609-2.608zm0 4.174c.888 0 1.566-.678 1.566-1.565 0-.887-.678-1.565-1.565-1.565-.887 0-1.565.678-1.565 1.565 0 .887.678 1.565 1.565 1.565zm.366-10.278a.504.504 0 0 1 0 .73l-8.348 8.348a.564.564 0 0 1-.365.157.564.564 0 0 1-.365-.157.504.504 0 0 1 0-.73l8.348-8.348a.504.504 0 0 1 .73 0z"
      />
    </defs>
    <use transform="translate(-8 -8)" xlinkHref="#percent_svg__a" />
  </svg>
)

export default SvgPercent
