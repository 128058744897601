"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var SvgPptx = function SvgPptx(props) {
  return _react["default"].createElement("svg", _extends({
    viewBox: "0 0 48 48"
  }, props), _react["default"].createElement("path", {
    fill: "#AA3111",
    d: "M44 27H4l-3-4 4-4h38l4 4z"
  }), _react["default"].createElement("path", {
    fill: "#E6E6E6",
    d: "M41 47H7a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h24l12 12v32a2 2 0 0 1-2 2z"
  }), _react["default"].createElement("path", {
    fill: "#B3B3B3",
    d: "M31 1v10a2 2 0 0 0 2 2h10L31 1z"
  }), _react["default"].createElement("path", {
    fill: "#D44519",
    d: "M45 41H3a2 2 0 0 1-2-2V23h46v16a2 2 0 0 1-2 2z"
  }), _react["default"].createElement("path", {
    fill: "#FFF",
    d: "M16.371 30.645c0 .878-.259 1.556-.776 2.033-.518.479-1.252.717-2.205.717h-.596V36h-2.122v-7.853h2.718c.992 0 1.737.217 2.234.649.498.434.747 1.051.747 1.849zm-3.578 1.021h.387c.319 0 .572-.09.76-.27.188-.179.282-.426.282-.74 0-.53-.293-.795-.881-.795h-.548v1.805zM23.321 30.645c0 .878-.259 1.556-.776 2.033-.518.479-1.252.717-2.205.717h-.596V36h-2.122v-7.853h2.718c.992 0 1.737.217 2.234.649.498.434.747 1.051.747 1.849zm-3.577 1.021h.387c.319 0 .572-.09.76-.27.188-.179.282-.426.282-.74 0-.53-.293-.795-.881-.795h-.548v1.805zM28.037 36h-2.122v-6.117h-1.917v-1.735h5.951v1.735h-1.912V36zM38.145 36H35.69l-1.531-2.455L32.645 36h-2.401l2.605-4.018-2.449-3.835h2.353l1.418 2.428 1.364-2.428h2.422l-2.487 4.001L38.145 36z"
  }));
};

var _default = SvgPptx;
exports["default"] = _default;