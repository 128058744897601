export const imports = {
  'docs/Absenteeism.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-absenteeism" */ 'docs/Absenteeism.mdx'
    ),
  'docs/Accordion.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-accordion" */ 'docs/Accordion.mdx'
    ),
  'docs/ActionBanner.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-action-banner" */ 'docs/ActionBanner.mdx'
    ),
  'docs/Activity.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-activity" */ 'docs/Activity.mdx'
    ),
  'docs/AddressListOnMap.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-address-list-on-map" */ 'docs/AddressListOnMap.mdx'
    ),
  'docs/ArticleContent.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-article-content" */ 'docs/ArticleContent.mdx'
    ),
  'docs/ArticleHeader.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-article-header" */ 'docs/ArticleHeader.mdx'
    ),
  'docs/ArticleList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-article-list" */ 'docs/ArticleList.mdx'
    ),
  'docs/AsideCards.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-aside-cards" */ 'docs/AsideCards.mdx'
    ),
  'docs/Banner.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-banner" */ 'docs/Banner.mdx'
    ),
  'docs/BarChart.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-bar-chart" */ 'docs/BarChart.mdx'
    ),
  'docs/BarChartBox.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-bar-chart-box" */ 'docs/BarChartBox.mdx'
    ),
  'docs/BarTable.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-bar-table" */ 'docs/BarTable.mdx'
    ),
  'docs/BenefitsTable.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-benefits-table" */ 'docs/BenefitsTable.mdx'
    ),
  'docs/Button.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-button" */ 'docs/Button.mdx'
    ),
  'docs/CallbackFormModal.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-callback-form-modal" */ 'docs/CallbackFormModal.mdx'
    ),
  'docs/ChartBox.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-chart-box" */ 'docs/ChartBox.mdx'
    ),
  'docs/ColoredHeaderContainer.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-colored-header-container" */ 'docs/ColoredHeaderContainer.mdx'
    ),
  'docs/CommissionsRepartition.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-commissions-repartition" */ 'docs/CommissionsRepartition.mdx'
    ),
  'docs/CompaniesLikeMe.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-companies-like-me" */ 'docs/CompaniesLikeMe.mdx'
    ),
  'docs/Confirmation.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-confirmation" */ 'docs/Confirmation.mdx'
    ),
  'docs/ContactCTA.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-contact-cta" */ 'docs/ContactCTA.mdx'
    ),
  'docs/ContactCards.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-contact-cards" */ 'docs/ContactCards.mdx'
    ),
  'docs/ContactForm.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-contact-form" */ 'docs/ContactForm.mdx'
    ),
  'docs/Container.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-container" */ 'docs/Container.mdx'
    ),
  'docs/ContractDetails.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-contract-details" */ 'docs/ContractDetails.mdx'
    ),
  'docs/ContractItem.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-contract-item" */ 'docs/ContractItem.mdx'
    ),
  'docs/ContractList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-contract-list" */ 'docs/ContractList.mdx'
    ),
  'docs/CuttingTitle.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-cutting-title" */ 'docs/CuttingTitle.mdx'
    ),
  'docs/DashboardBanner.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-dashboard-banner" */ 'docs/DashboardBanner.mdx'
    ),
  'docs/DataTable.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-data-table" */ 'docs/DataTable.mdx'
    ),
  'docs/DocumentCenter.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-document-center" */ 'docs/DocumentCenter.mdx'
    ),
  'docs/DocumentsList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-documents-list" */ 'docs/DocumentsList.mdx'
    ),
  'docs/EditorialContent.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-editorial-content" */ 'docs/EditorialContent.mdx'
    ),
  'docs/Footer.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-footer" */ 'docs/Footer.mdx'
    ),
  'docs/FooterBottom.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-footer-bottom" */ 'docs/FooterBottom.mdx'
    ),
  'docs/FormInfos.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-form-infos" */ 'docs/FormInfos.mdx'
    ),
  'docs/Forms.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-forms" */ 'docs/Forms.mdx'
    ),
  'docs/Formula.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-formula" */ 'docs/Formula.mdx'
    ),
  'docs/FourCards.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-four-cards" */ 'docs/FourCards.mdx'
    ),
  'docs/GoogleMaps.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-google-maps" */ 'docs/GoogleMaps.mdx'
    ),
  'docs/Header.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-header" */ 'docs/Header.mdx'
    ),
  'docs/HealthOverview.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-health-overview" */ 'docs/HealthOverview.mdx'
    ),
  'docs/Histogram.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-histogram" */ 'docs/Histogram.mdx'
    ),
  'docs/Icon.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-icon" */ 'docs/Icon.mdx'
    ),
  'docs/Iframe.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-iframe" */ 'docs/Iframe.mdx'
    ),
  'docs/IllustratedCTA.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-illustrated-cta" */ 'docs/IllustratedCTA.mdx'
    ),
  'docs/InfoBox.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-info-box" */ 'docs/InfoBox.mdx'
    ),
  'docs/InputAddress.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-input-address" */ 'docs/InputAddress.mdx'
    ),
  'docs/InputSelect.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-input-select" */ 'docs/InputSelect.mdx'
    ),
  'docs/InputText.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-input-text" */ 'docs/InputText.mdx'
    ),
  'docs/ItemList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-item-list" */ 'docs/ItemList.mdx'
    ),
  'docs/LargeCards.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-large-cards" */ 'docs/LargeCards.mdx'
    ),
  'docs/Link.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-link" */ 'docs/Link.mdx'
    ),
  'docs/ListFilters.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-list-filters" */ 'docs/ListFilters.mdx'
    ),
  'docs/MapCards.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-map-cards" */ 'docs/MapCards.mdx'
    ),
  'docs/MembersList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-members-list" */ 'docs/MembersList.mdx'
    ),
  'docs/Modal.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-modal" */ 'docs/Modal.mdx'
    ),
  'docs/NavigationBanner.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-navigation-banner" */ 'docs/NavigationBanner.mdx'
    ),
  'docs/Notification.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-notification" */ 'docs/Notification.mdx'
    ),
  'docs/Notification2.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-notification2" */ 'docs/Notification2.mdx'
    ),
  'docs/NotificationToast.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-notification-toast" */ 'docs/NotificationToast.mdx'
    ),
  'docs/Pagination.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-pagination" */ 'docs/Pagination.mdx'
    ),
  'docs/PensionDashboard.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-pension-dashboard" */ 'docs/PensionDashboard.mdx'
    ),
  'docs/PieChart.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-pie-chart" */ 'docs/PieChart.mdx'
    ),
  'docs/PieChartBox.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-pie-chart-box" */ 'docs/PieChartBox.mdx'
    ),
  'docs/Pill.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-pill" */ 'docs/Pill.mdx'
    ),
  'docs/ProfilInfoList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-profil-info-list" */ 'docs/ProfilInfoList.mdx'
    ),
  'docs/Profile.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-profile" */ 'docs/Profile.mdx'
    ),
  'docs/QuickContractList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-quick-contract-list" */ 'docs/QuickContractList.mdx'
    ),
  'docs/QuickNews.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-quick-news" */ 'docs/QuickNews.mdx'
    ),
  'docs/ReminderCTA.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-reminder-cta" */ 'docs/ReminderCTA.mdx'
    ),
  'docs/RoundedCards.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-rounded-cards" */ 'docs/RoundedCards.mdx'
    ),
  'docs/SavingSlice.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-saving-slice" */ 'docs/SavingSlice.mdx'
    ),
  'docs/Savings.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-savings" */ 'docs/Savings.mdx'
    ),
  'docs/SearchAction.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-search-action" */ 'docs/SearchAction.mdx'
    ),
  'docs/ServiceCard.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-service-card" */ 'docs/ServiceCard.mdx'
    ),
  'docs/ServiceSelector.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-service-selector" */ 'docs/ServiceSelector.mdx'
    ),
  'docs/SimpleButton.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-simple-button" */ 'docs/SimpleButton.mdx'
    ),
  'docs/SimpleFilters.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-simple-filters" */ 'docs/SimpleFilters.mdx'
    ),
  'docs/SimulatorView.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-simulator-view" */ 'docs/SimulatorView.mdx'
    ),
  'docs/Slice.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-slice" */ 'docs/Slice.mdx'
    ),
  'docs/SquareButton.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-square-button" */ 'docs/SquareButton.mdx'
    ),
  'docs/Table.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-table" */ 'docs/Table.mdx'
    ),
  'docs/TabsContent.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-tabs-content" */ 'docs/TabsContent.mdx'
    ),
  'docs/Text.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-text" */ 'docs/Text.mdx'
    ),
  'docs/ThreeCards.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-three-cards" */ 'docs/ThreeCards.mdx'
    ),
  'docs/Thumbnails.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-thumbnails" */ 'docs/Thumbnails.mdx'
    ),
  'docs/Tip.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-tip" */ 'docs/Tip.mdx'
    ),
  'docs/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-index" */ 'docs/index.mdx'
    ),
  'docs/theme.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-theme" */ 'docs/theme.mdx'
    ),
}
