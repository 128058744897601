import React from 'react'

const SvgServer = props => (
  <svg viewBox="0 0 24 20" {...props}>
    <defs>
      <path
        id="server_svg__a"
        d="M0 9.391V0h24v9.391H0zm1.043-1.043h21.914V1.043H1.043v7.305zM0 19.826v-9.391h24v9.391H0zm1.043-1.043h21.914v-7.305H1.043v7.305zm18.261-14.61a.522.522 0 1 0 .001 1.045.522.522 0 0 0 0-1.044zm0 2.088a1.567 1.567 0 0 1-1.565-1.565c0-.863.702-1.566 1.565-1.566s1.566.703 1.566 1.566c0 .863-.703 1.565-1.566 1.565zm0 8.348a.522.522 0 1 0 0 1.044.522.522 0 0 0 0-1.044zm0 2.087a1.567 1.567 0 0 1-1.565-1.566c0-.863.702-1.565 1.565-1.565s1.566.702 1.566 1.565-.703 1.566-1.566 1.566z"
      />
    </defs>
    <use fillRule="evenodd" xlinkHref="#server_svg__a" />
  </svg>
)

export default SvgServer
